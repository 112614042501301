/* /components/Navbar.css */
.navbar {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 100;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  margin: 0 20px;
}

.nav-links {
  display: flex;
  gap: 20px;
}

.nav-link {
  text-decoration: none;
  color: var(--primary-color);
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  color: black !important;
}

.nav-links a:hover {
  color: var(--primary-color)b4;
}

.logo a {
  font-size: 1.5rem;
  font-weight: 700;
  text-decoration: none;
  color: var(--primary-color);

  display: flex;
  align-items: center;
  color: black !important;
}

.login-button-home {
  background-color: #e9e9e9 !important;
  padding: 8px 16px;
  border-radius: 8px;
}

.signup-button-home {
  background-color: black;
  color: white !important;
  padding: 8px 16px;
  border-radius: 8px;
}

.dashboard-button-home {
  padding: 8px 16px;
  background-color: black !important;
  border-radius: 8px;
  text-decoration: none;
  color: white !important;
  font-size: 0.9rem;
}