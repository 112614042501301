.account-top-section {
    display: flex;
    gap: 20px;
    
    align-items: center;
}

.fallback-profile {
    aspect-ratio: 1/1;
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.fallback-profile p {
    width: 100%;
    text-align: center;
    font-size: 25px;
}

.profile-picture {
    aspect-ratio: 1/1;
    border-radius: 50%;
    width: 80px;
    overflow: hidden;
    object-fit: cover;
    transition: opacity 0.3s ease;
    position: relative;
    z-index: 1;
}

.profile-picture-edit {
    position: relative;
    cursor: pointer;
    transition: opacity 0.3s ease;
    width: fit-content;
    aspect-ratio: 1/1;
    
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.profile-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 50%;
    transition: opacity 0.3s ease;
    z-index: 2;
    aspect-ratio: 1/1;
    backdrop-filter: blur(2px);
}

.edit-button {
    background-color: var(--primary-color);
    border: none;
    padding: 8px 16px;
    border-radius: 20px;
    cursor: pointer;
    font-size: 14px;
    transition: transform 0.2s ease;
}

.team-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 25%;
    transition: opacity 0.3s ease;
    z-index: 2;
    aspect-ratio: 1/1;
    backdrop-filter: blur(2px);
}

.edit-button:hover {
    transform: scale(1.05);
}

.fallback-profile {
    transition: opacity 0.3s ease;
    position: relative;
    z-index: 1;
}

.fallback-profile p {
    color: white;
    font-weight: 500;
}

.icon-overlay {
    color: white !important;
}

.account-name-title{
    display: flex;
    align-items: center;
    margin: 0;
}

.details {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 15px;
}

.details-item {
    display: flex;
    gap: 10px;
    align-items: center;
}

.details-item input {
    border: none;
    border: 1px solid var(--subtitle-color);
    border-radius: 5px;
    background-color: transparent;
    color: var(--primary-color);
    font-size: 14px;
    padding: 5px;
    min-width: 25%;
}

.spacing-20 {
    height: 20px;
}

.delete-button {
    background-color: var(--error-color);
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 20px;
    cursor: pointer;
    font-size: 14px;
    transition: transform 0.2s ease;
}