:root {
  --bg-color: #ffffff;

  --frosted-bg: linear-gradient(180deg, hsla(0, 0%, 100%, 0.5) 0%, hsla(0, 0%, 100%, 0.1) 100%);
  --frosted-br: rgba(255, 255, 255, 0.5);

  --popup-bg: rgba(255, 255, 255, 0.8);

  --skeleton-bg: rgba(0, 0, 0, 0.025);

  --primary-color: black;
  --secondary-color: white;
  --subtitle-color: #0000007a;

  --error-color: #ff0000;
}

[data-theme="dark"] {
  --bg-color: black;

  --frosted-bg: linear-gradient(180deg, hsla(0, 0%, 100%, 0.1) 0%, hsla(0, 0%, 100%, 0.01) 100%);
  --frosted-br: rgba(255, 255, 255, 0.1);

  --popup-bg: hsla(220, 5%, 12%, 0.8);

  --skeleton-bg: rgba(255, 255, 255, 0.032);

  --primary-color: white;
  --secondary-color: black;
  --subtitle-color: #ffffff54;
  
  --error-color: #ff0000;
}

* {
  transition: background 0.3s, color 0.3s;
}

body {
  margin: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
  all: unset;
  text-align: center;
}