.screen-options {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-bottom: 25px;
}

.screen-options-option {
    display: flex;
    flex-direction: column;

    align-items: center;
    gap: 5px;
    cursor: pointer;

    flex: 1;
}

.option-tick-theme {
    width: 24px;
    height: 24px;

    background-color: var(--primary-color);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon-theme-select {
    color: var(--primary-color);
}

.light-mode-theme-preview {
    aspect-ratio: 16/9;
    width: 100%;
    background-color: #ffffff;
    border-radius: 8px;

    box-shadow: 0 0px 20px rgba(0, 0, 0, 0.05);

    margin-bottom: 20px;
    transition: transform 0.5s ease;
}

.light-mode-theme-preview:hover {
    transform: scale(1.01);
}

.dark-mode-theme-preview {
    aspect-ratio: 16/9;
    width: 100%;

    background-color: #000000;
    border-radius: 8px;

    box-shadow: 0 0px 20px rgba(0, 0, 0, 0.2);

    margin-bottom: 20px;
    transition: transform 0.5s ease;
}

.dark-mode-theme-preview:hover {
    transform: scale(1.01);
}


.mode-theme-preview-inner {
    height: 93%;
    width: 96%;
    border-radius: 8px;
    background:
        radial-gradient(circle at 95% -10%, #b078ff4a 0%, transparent 40%),
        radial-gradient(circle at 10% 100%, #b078ff3e 0%, transparent 40%),
        radial-gradient(circle at 50% 35%, #b078ff2e 0%, transparent 30%),
        radial-gradient(circle at 75% 90%, #50ddf948 0%, transparent 40%),
        radial-gradient(circle at 30% 55%, #50ddf948 0%, transparent 40%);

    display: flex;
    padding: 2%;

}

.preview-inner-left-widget {
    height: 100%;
    width: 40%;
}

.light-widget-preview {
    background: linear-gradient(180deg, hsla(0, 0%, 95%, 0.5) 0%, hsla(0, 0%, 100%, 0.1) 100%);

    border-radius: 6px;
    backdrop-filter: blur(20px);
    border: 1px solid rgba(255, 255, 255, 0.562);
    border-radius: 6px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.01);
    box-sizing: border-box;
}

.dark-widget-preview {
    background: linear-gradient(180deg, hsla(0, 0%, 100%, 0.2) 0%, hsla(0, 0%, 45%, 0.2) 100%);

    border-radius: 6px;
    backdrop-filter: blur(20px);
    border: 1px solid rgba(255, 255, 255, 0.237);
    border-radius: 6px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.01);
    box-sizing: border-box;
}

.preview-top-bit-preview {
    height: 33%;
    width: 98%;

    display: flex;
    gap: 2%;
    margin-left: 2%;
}

.preview-top-left {
    width: 65%;
    height: 100%;
}

.preview-top-right {
    width: 35%;
    height: 100%;
}

.preview-inner-main {
    height: 67%;
    width: 98%;

    display: flex;
    gap: 2%;
    margin-left: 2%;
    margin-top: 2%;
}

.main-section-preview {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
}

.switch {
    position: relative;
    display: inline-block;
    width: 34px;
    height: 20px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--subtitle-color);
    transition: .4s;
    border-radius: 34px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 3px;
    bottom: 3px;
    background-color: var(--secondary-color);
    transition: .4s;
    border-radius: 50%;
}

input:checked+.slider {
    background-color: var(--primary-color);
}

input:checked+.slider:before {
    transform: translateX(14px);
}

.dropdown {
    padding: 4px;
    border-radius: 8px;
    border: 1px solid var(--subtitle-color);
    background-color: transparent;
    color: var(--primary-color)
}