.header-route {
    margin: 0;
    font-size: 14px;
    color: var(--primary-color);
    display: flex;
    align-items: center;
}

.header {
    display: flex;
    flex-direction: row;
}

.fill {
    flex: 1;
}

.header-actions {
    display: flex;
    flex-direction: row;
    gap: 0px;
    align-items: center;
}

.search-container {
    position: relative;
    display: flex;
    align-items: center;
    margin-right: 20px;
    border-radius: 15px;
}

.search-icon {
    position: absolute;
    left: 9px;
    color: var(--primary-color);
}

.search-icon-bell {
    color: var(--primary-color);
}

.search-input {
    padding: 5px 10px 5px 30px;
    border-radius: 8px;
    border: 1px solid #ffffff46;
    width: 200px;
    color: var(--primary-color);

    background: var(--frosted-bg);
    box-shadow: #0000000b 0px 0px 10px;
}

.search-input::placeholder {
    color: var(--primary-color);
}

.search-shortcut {
    position: absolute;
    right: 10px;
    font-size: 12px;
    color: var(--primary-color);
}

.breadcrumb-separator {
    margin: 0 10px;
    color: var(--primary-color);
}

.breadcrumb-item {
    padding: 4px 8px;
    border-radius: 8px;
}

.breadcrumb-item:hover {
    background-color: var(--frosted-br);
}