@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');

.side-nav {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 0;
    box-sizing: border-box;
    transition: width 0.3s ease, background 0.3s ease, box-shadow 0.3s ease;
    background: var(--frosted-bg);
    backdrop-filter: blur(20px);
    border-right: 1px solid var(--frosted-br);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.04);
    min-width: 70px;
    max-width: 300px;
    width: 70px;
    padding-left: 10px;
    padding-right: 10px;
    overflow: hidden;
}

.side-nav:hover,
.side-nav.sel-dd {
    width: 200px;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
}

.nav-item {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 13px;
    cursor: pointer;
    text-decoration: none;
    color: var(--primary-color);
    box-sizing: border-box;
    border-radius: 14px;
    margin-bottom: 5px;
    transition: background-color 0.3s ease, transform 0.2s ease, padding 0.2s ease;
    gap: 10px;
}

.nav-item:hover {
    background-color: var(--skeleton-bg);
    padding-left: 20px;
    border-radius: 14px;
}

.icon {
    flex-shrink: 0;
    transition: transform 0.2s ease, margin-left 0.2s ease;
    min-width: 24px;
    display: flex;
    justify-content: center;
}

.nav-text {
    opacity: 0;
    white-space: nowrap;
    visibility: hidden;
    transition: opacity 0.2s ease, visibility 0s 0.2s;
    display: block;
    overflow: hidden;
    width: fit-content;
}

.nav-logo-img {
    width: 52px;
    margin-left: -2px;
    margin-right: -2px;
}

.nav-logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 5%;
    margin-top: 10px;
    margin-bottom: 15px;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
    transition: background-color 0.3s ease, transform 0.2s ease, padding 0.2s ease;
}

.nav-text.loopify {
    font-size: 48px;
    font-weight: bold;
    font-family: 'Quicksand', sans-serif !important;
    margin-bottom: 10px;
    width: fit-content;
}

.letterspacing {
    letter-spacing: -3px;
}

.side-nav:hover .nav-text,
.side-nav.sel-dd .nav-text {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.2s ease, visibility 0s;
}

.selected-page {
    background-color: var(--frosted-br);
    font-weight: 600;
}

.bottom-nav {
    margin-top: auto;
    width: 100%;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.3s ease, transform 0.3s ease;
}

.side-nav:hover .bottom-nav {
    opacity: 1;
    transform: translateY(0);
}

.menu-divider {
    width: 100%;
    height: 1px;
    background-color: var(--frosted-br);
    margin: 10px 0;
}

.menu-size-picture {
    width: 45px !important;
}

.nav-item-logo {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 2px;
    cursor: pointer;
    text-decoration: none;
    color: var(--primary-color);
    box-sizing: border-box;
    border-radius: 8px;
    margin-bottom: 5px;
    transition: background-color 0.3s ease, transform 0.2s ease, padding 0.2s ease;
    gap: 10px;
}

.nav-item-logo:hover {
    transform: scale(1.02);
    background-color: var(--skeleton-bg);
    border-radius: 14px;
}

.team-side-bar {
    display: flex;
    flex-direction: column;
    gap: 1px;
}

.team-side-bar p {
    margin: 0;
    padding: 0;
    font-size: 12px;
    color: var(--subtitle-color);
}

.side-bar-team-name {
    font-size: 16px !important;
    color: var(--primary-color) !important;
}

.teamSel-dd-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: transparent;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.1);
}

.teamSel-dd-content {
    position: absolute;
    bottom: 8%;
    left: 1%;
    padding: 10px;
    max-width: 30vw;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.teamSel-dd-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.teamSel-dd-item-name {
    font-size: 16px;
    color: var(--primary-color);
    margin: 0;
}

.teamSel-dd-add {
    display: flex;
    align-items: center;
    gap: 0px;
    cursor: pointer;
    color: var(--primary-color);
    text-decoration: none;
}

.teamSel-dd-item {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    color: var(--primary-color);
    text-decoration: none;
}

.divider-dd {
    border-bottom: 1px solid var(--subtitle-color);
}

.menu-size-picture-icon {
    width: 30px;
}

.nav-item.post {
    background-color: var(--primary-color);
}

.nav-item.post .icon, .nav-item.post .nav-text {
    color: var(--secondary-color);
}