/* Background styling */
.auth-body {
    background-color: white;
    position: relative;
    overflow: hidden;
    height: 100vh;

    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.auth-body::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 75%;
    width: 75vh;
    height: 100vh;
    background-color: #54b0e9;
    border-radius: 0%;
    filter: blur(200px);
    transform: translate(-50%, -50%);
    z-index: -1;
}

/* Form container styling */
.form-container {
    width: 50vw;
    background-color: white;
    padding: 40px;
    border-radius: 16px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    margin: 50px auto;

    padding: 32px 32px;
}

.form-container h2 {
    text-align: center;
    margin-bottom: 20px;
    font-weight: 500;
    margin: 0px;
}

.form-container form {
    display: flex;
    flex-direction: column;
}

.form-container input {
    width: 100%;
    border: 1px solid #cccccc75;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 8px;
    margin-top: 8px;
}

.form-container .submit-button {
    padding: 10px;
    background-color: #6A94DB;
    color: white;
    border: none;
    cursor: pointer;
    margin-top: 20px;
    margin-bottom: 0px;
}

.form-container .submit-button:hover {
    background-color: #6a93dbae;
}

.form-container .google-button {
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    width: 100%;
}

.form-container .google-button:hover {
    background-color: #f1f1f1;
}

.form-container button {
    border: #00000012 1px solid;
    border-radius: 10px;
    cursor: pointer;
}

.password-strength {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.strength-bar {
    width: 23%;
    height: 10px;
    background-color: #e0e0e0;
    border-radius: 4px;
}

.strength-bar.filled {
    background-color: red;
}

.strength-bar.filled:nth-child(2)~.strength-bar.filled {
    background-color: orange;
}

.strength-bar.filled:nth-child(3)~.strength-bar.filled,
.strength-bar.filled:nth-child(4)~.strength-bar.filled {
    background-color: green;
}

.team-size-buttons {
    display: flex;
    gap: 10px;
    margin-top: 10px;
}

.team-size-button {
    padding: 10px 20px;
    border: 2px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
    color: #333;
    cursor: pointer;
    transition: all 0.3s ease;
}

.team-size-button:hover {
    background-color: #eaeaea;
}

.team-size-button.selected {
    background-color: #007bff;
    color: white;
}

.select {
    width: 100%;
    padding: 10px;
    border: 1px solid #cccccc75;
    box-sizing: border-box;
}

a.forgot {
    color: #007bff;
    text-decoration: none;
    font-size: 12px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.inputs {
    gap: 20px;
    display: flex;
    flex-direction: column;
}

.subtitle {
    font-size: 14px;
    color: var(--subtitle-color);
    margin-top: 10px;
    text-align: center;
    margin: 0;
}

.subtitle a {
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
}

.or {
    display: flex;
    align-items: center;
    text-align: center;
    margin: 28px 0;
    color: black;

    font-size: 16px;
}

.or::before {
    content: '';
    flex: 1;
    border-bottom: 1px solid #ccc;
    margin-right: 18px;
}

.or::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #ccc;
    margin-left: 18px;
}

.login-title {
    color: black;
    margin-bottom: 20px !important;
}

.label-login {
    color: black;
    font-size: 14px;
}
.error-message {
    color: red;
    font-size: 14px;
    margin-bottom: 5px;
}

.auth-body * {
    color: black;
}