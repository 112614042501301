.options {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.options-hov {
    display: flex;
    flex-direction: row;
    gap: 18px;
    cursor: pointer;
}

.option {
    border: #DBDEE4 1px solid;
    border-radius: 8px;
    cursor: pointer;
    flex: 1;
}

.option:hover {
    background-color: var(--skeleton-bg);
}

.selected {
    position: relative;
    border-radius: 10px;
    background: linear-gradient(to right, #6A94DB, #8A2BE2);
}

.selected::before {
    content: "";
    position: absolute;
    inset: 1px;
    background: #fff;
    border-radius: 8px;
    z-index: 1;
}

.selected * {
    position: relative;
    z-index: 2; /* Ensures text appears above */
    color: black; /* Change color if needed */
}

.option h3 {
    margin: 10px;
    font-weight: bold;
    font-size: 18px;
}

.option p {
    margin: 10px;
    color: #333;
    font-size: 12px;
}

.section {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.pricing {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.input-create-team-name {
    padding: 8px;
    border: none;
    border-bottom: var(--subtitle-color) 1px solid;
    text-align: center;
    color: var(--primary-color);
    background-color: transparent;
    font-size: 1rem;
    flex: 1;
}

.input-create-team-name::placeholder {
    color: var(--subtitle-color);
    opacity: 1;
}