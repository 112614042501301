.settings-body {
    display: flex;
    flex-direction: row;
    height: 100%;
    gap: 20px;
    margin-top: 20px;
    min-height: 0;
}

.settings-side {
    padding: 20px;
    flex-shrink: 0;
    flex-grow: 0;
    height: 100%;
}

.settings-main {
    flex-grow: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.dynamic-main-settings {
    flex-grow: 1;
    min-height: 0;
    overflow-y: auto;
}

.settings-body p {
    margin: 0;
    color: var(--primary-color);
    font-size: 14px;
}

.settings-header-section {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    margin-bottom: 10px;
}

.settings-body-section {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-left: 10px;
}

.settings-body-item {
    display: flex;
    flex-direction: row;
    align-items: center;

    cursor: pointer;
    padding: 5px;

    text-decoration: none;
    color: var(--primary-color);
    gap: 5px;
}

.settings-body-item:hover {
    background-color: var(--primary-color)10;
    border-radius: 5px;
}

.settings-body-sub {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.main-header-control {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}

.main-body-settings {
    width: 100%;
    height: 100%;
    margin-top: 20px;
}

.main-body-settings table {
    width: 100%;
    border-collapse: collapse;
}

.main-body-settings table th {
    text-align: left;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--subtitle-color);
}

.main-body-settings table th p {
    margin: 0;
    color: rgba(0, 0, 0, 0.3);
    font-size: 12px;
    font-weight: 500;
    padding-left: 10px;
}

.main-body-settings table td {
    padding: 10px;
    border-bottom: 1px solid var(--subtitle-color);
}

.table-row td:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.table-row td:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 8px;
}

.table-row:hover td {
    background-color: var(--skeleton-bg); /* Change this color to your desired hover color */
    border-bottom: 0;
}

.inner-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px;
    min-height: min-content;
}

.img-mini{
    width: 25px !important;
}

.img-mini p {
    font-size: 10px !important;
}