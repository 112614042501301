.schedule-body {
    display: flex;
    flex-direction: row;

    height: 100%;
    gap: 20px;
    margin-top: 20px;
    min-height: 0;
    box-sizing: border-box;
}

.schedule-side {
    padding: 10px;
    flex: 0 0 1;

    height: 100%;
}

.schedule-main {
    flex: 1;
    /* Allow the container to grow and shrink */
    min-width: 0;
    /* Prevent overflowing content from forcing a wider width */
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.dynamic-main-schedule {
    flex: 1;
    min-height: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;
    overflow: hidden;
}

.top-toggles {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-bottom: 10px;
}

.schedule-header-section {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    margin-bottom: 10px;
}

.schedule-header-section p {
    margin: 0;
    color: white;
    font-size: 14px;
}

.schedule-body-section {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-left: 10px;
}

.schedule-body-item {
    display: flex;
    flex-direction: row;
    align-items: center;

    cursor: pointer;
    padding: 5px;

    text-decoration: none;
    color: var(--primary-color);
    gap: 5px;
}

.schedule-body-item:hover {
    background-color: var(--primary-color)10;
    border-radius: 5px;
}

.divider {
    border-bottom: 1px solid var(--subtitle-color);
    margin: 16px 0;
}

.schedule-body-sub {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.main-header-control {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}

.main-body-schedule {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 10px;
    width: 100%;
    overflow: hidden;
}

.main-body-schedule p {
    margin: 0;
    color: var(--primary-color);
}

.main-body-schedule table td {
    padding: 4px;
}

.main-body-schedule table th {
    font-size: 14px;
    font-weight: 400;
    color: #666;
}

.inner-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px;
    min-height: min-content;
}

.platforms-section {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.platform-item, .brand-item {
    display: flex;
    flex-direction: column;
    border-top: 1px solid var(--subtitle-color);
    padding-top: 10px;
}

.platform-item-header {
    font-size: 14px !important;
    margin: 0;
    margin-bottom: 10px;
    color: var(--primary-color);

    display: flex;
    flex-direction: row;
    gap: 5px;
}

.top-toggles {
    background-color: var(--frosted-br);
    padding: 5px;
    border-radius: 10px;
    cursor: pointer;
}

.schedule-header-item p {
    padding: 5px;
    border-radius: 5px;
}

.schedule-header-item.active p {
    background-color: var(--frosted-br);
    color: var(--primary-color);
    border-radius: 5px;
}

.platform-item-body {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.platform-item-body p {
    font-size: 13px !important;
    margin: 10px;
    margin-left: 16px;
    color: var(--primary-color);
}

.img-mini {
    width: 25px !important;
}

.img-mini p {
    font-size: 10px !important;
}

.main-header {
    height: 20%;
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.left-top-stats {
    width: 70%;
    padding: 16px;
    gap: 10px;
    display: flex;
    flex-direction: column;
}

.right-top-stats {
    width: 30%;
    display: flex;
    flex-direction: column;
    padding: 16px;
}

.section-header-stats {
    margin: 0;
    font-size: 16px;
    color: var(--primary-color);
}

.header-stats-body {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 100%;
}

.schedule-header-body-schedule {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
}

.week-navigation {
    align-items: center;
    display: flex;
    gap: 20px;
}

.view-select-buttons {
    display: flex;
    flex-direction: row;
    background-color: var(--frosted-br);
    padding: 3px;
    border-radius: 8px;
    cursor: pointer;
}

.view-button {
    font-size: 14px;
    padding: 5px 16px;
    border-radius: 6px;
}

.view-button.active {
    background-color: var(--frosted-br);
    color: var(--primary-color);
}

/* Week View */
.week-view {
    display: grid;
    grid-template-columns: repeat(7, minmax(0, 0.2fr));
    min-height: 100%;
}

.day-column {
    min-width: 0;
    display: flex;
    flex-direction: column;
    border-right: 1px solid var(--subtitle-color);
}

.day-column:nth-child(7) {
    border-right: none;
}

.day-header {
    padding: 4px;
    text-align: center;
    flex-shrink: 0;
}

.day-posts {
    flex: 1;
    min-height: 0;
    overflow-y: auto;
}

.post-item {
    margin: 6px;
    padding: 6px;
    border-radius: 8px;
    min-width: 0;
    background-color: var(--subtitle-color);
}

.post-title {
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    color: black !important;
}

.post-time {
    margin: 4px 0 !important;
    font-size: 10px;
    color: grey !important;
}

/* Month View */
.month-grid {
    display: grid;
    grid-template-columns: repeat(7, minmax(0, 0.2fr));
    grid-auto-rows: 0.2fr;
    height: 100%;
}

.day-cell {
    min-width: 0;
    display: flex;
    flex-direction: column;
    border: 0.5px solid var(--frosted-br);
    padding: 0.5rem;
    overflow: auto;
}

.cell-header {
    font-size: 0.9rem;
    font-weight: bold;
    margin-bottom: 0.25rem;
}

.post-bubble {
    border-radius: 4px;
    padding: 0.25rem;
    margin: 0.1rem 0;
    min-width: 0;
    /* Crucial for text truncation */
}

.post-bubble p {
    font-size: 0.75rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0;
}

/* List View */
.list-view {
    width: 100%;
    overflow: hidden;
}

.list-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 20px 18px;
    overflow: auto;
}

.list-date {
    font-size: 0.9rem;
    color: #666;
}

.list-item {
    border-bottom: 1px solid #666;
    border-color: #666;
    width: 100%;
    overflow: hidden;
}

.post-details h4 {
    font-size: 1rem;
    margin: 0 0 0.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.post-details p {
    font-size: 0.9rem;
    color: #666;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

/* General scaling rules */
.schedule-view {
    height: 100%;
    width: 100%;
    margin-top: 10px;
    overflow: auto;
}

.day-cell:nth-child(1) {
    border-top-left-radius: 10px;
}

.day-cell:nth-child(7) {
    border-top-right-radius: 10px;
}

.day-cell:nth-child(29) {
    border-bottom-left-radius: 10px;
}

.day-cell:nth-child(35) {
    border-bottom-right-radius: 10px;
}

/* Post Status Colors */
.post-item.scheduled,
.post-bubble.scheduled {
    background-color: #D3EAFF !important;
}

.post-item.posted,
.post-bubble.posted {
    background-color: #EAF8D3 !important;
}

.post-item.hidden,
.post-bubble.hidden {
    background-color: #AFAFAFAF !important;
}

.post-item.deleted,
.post-bubble.deleted {
    background-color: #FFD3D3 !important;
}

.cell-posts {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.list-view {
    width: 100%;
    overflow: auto;
}

.list-table .title {
    max-width: 0;
    width: auto;
}

.title-content {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 0;
}

.list-table .channel,
.list-table .platform,
.list-table .list-date-status,
.list-table .list-status {
  white-space: nowrap;
  width: 1%; /* Force columns to minimum width */
}

.list-content {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.list-date {
    font-size: 12px;
    text-align: center;
}

.post-details h4 {
    color: var(--primary-color);
    font-weight: 400;
    font-size: 14px;
    margin: 0;
}

td.list-status {
    display: table-cell;
    vertical-align: middle;
    white-space: nowrap;
}

.list-status-content {
    display: flex;
    align-items: center;
    gap: 5px;
}

.status-circle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 5px;
}

.status-circle.scheduled {
    background-color: #D3EAFF;
}

.status-circle.posted {
    background-color: #EAF8D3;
}

.status-circle.hidden {
    background-color: #AFAFAFAF;
}

.status-circle.deleted {
    background-color: #FFD3D3;
}

/*text*/
.list-status-content.scheduled {
    color: #D3EAFF;
}

.list-status-content.posted {
    color: #EAF8D3;
}

.list-status-content.hidden {
    color: #AFAFAFAF;
}

.list-status-content.deleted {
    color: #FFD3D3;
}

td.list-date-status {
    display: table-cell;
    vertical-align: middle;
    white-space: nowrap;
}

.list-date-status-content {
    display: flex;
    align-items: center;
    gap: 5px;
    /* Adjust spacing between icon and text */
}

.post-footer {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
}

.img-very-mini {
    width: 20px !important;
    height: 20px !important;
}

.img-ultra-mini{
    width: 15px !important;
    height: 15px !important;
}

.post-platforms {
    gap: -10px;
}

.post-month-content {
    display: flex;
    flex-direction: row;
    gap: 5px;
    justify-content: space-between;
}

.stats-divider {
    border-right: 1px solid var(--subtitle-color);
    margin: 16px 0;
    height: 75%;
}

.stats-item {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 30%;
}

.title-stats-item {
    font-weight: 400;
    font-size: 14px;
    color: var(--subtitle-color);
}

.stats-item p {
    margin: 0;
    font-size: 16px;
    color: var(--primary-color);
    font-weight: bold;
}

.social-item {
    display: flex;
    flex-direction: row;
    gap: 5px;
    margin-left: 10px;
    align-items: center;
}

.social-item p {
    margin: 5px;
    font-size: 14px;
    color: var(--primary-color);
}