/* /pages/Home.css */
.home-container {
    text-align: center;
}


h1 {
    font-size: 5rem;
    color: black !important;
    margin: 0;
}

p {
    font-size: 1.2rem;
    margin-bottom: 20px;
    margin-top: 50px;
    color: #696969;
}

.hero-section {
    margin-top: 30vh;
}