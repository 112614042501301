.ss-page {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    /* overflow: hidden; */
    /* overflow-y: auto; */
}

.ss-container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: auto 1fr 1fr;
    gap: 20px;
    padding-top: 20px;
    flex-grow: 1;
    overflow-y: auto;
}

.small-container {
    grid-column: span 3;
    height: 100px;
    overflow: hidden;
}

.wide-container {
    grid-column: span 8;
    min-height: 100%;
    max-height: 0px;
    overflow-y: auto;

    display: flex;
    justify-content: center;
    align-items: center;
}

.graph-container {
    grid-column: span 8;
    min-height: 100%;
    max-height: 0px;
    overflow-y: auto;

    display: flex;
}

.medium-container {
    grid-column: span 6;
    min-height: 100%;
    max-height: 0px;

    overflow-y: auto !important;
}

.normal-container {
    grid-column: span 4;
    min-height: 100%;
    max-height: 0px;
    overflow: hidden;
}

.widget {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow: hidden;
}

.widget-title {
    font-size: 15px;
    padding: 0;
    margin: 0;
    color: var(--primary-color);

    display: flex;
    flex-direction: row;
    gap: 10px;
}

.widget-title-item {
    font-size: 15px;
    padding: 0;
    margin: 0;
    color: var(--primary-color);
    cursor: pointer;
}

.graph-main-dashboard {
    height: 100%;
    width: 100%;
}

.top-bar-skeleton {
    background-color: var(--skeleton-bg);
    flex-grow: 1;
    border-radius: 10px;
}

.followers-skeleton {
    display: flex;
    align-items: center;
    gap: 20px;
}

.circle-fol {
    width: 24px;
    height: 24px;
    background-color: var(--skeleton-bg);
    border-radius: 50%;
}

.text-fol {
    flex: 1;
    height: 16px;
    border-radius: 4px;
    background-color: var(--skeleton-bg);
}

.circle-bar-skeleton {
    position: relative;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background-color: var(--skeleton-bg);
    display: flex;
    align-items: center;
    justify-content: center;
}

.bars-skeletons {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.bar-skeleton {
    height: 20px;
    border-radius: 4px;
    background-color: var(--skeleton-bg);
}

.skeleton-data {
    display: flex;
    align-items: center;
    gap: 20px;
}

.data-skeleton {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-bottom: 10px;

    overflow-y: visible;
}

.bar-skeleton-text {
    margin: 0;
    color: var(--primary-color);
    font-size: 14px;
}

.graph-skeleton-header {
    display: flex;
    align-items: center;
    gap: 15%;
    padding: 5px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.048);
}

.graph-skeleton {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.bar-skeletons {
    display: flex;
    flex-direction: column;
    gap: 20px;
    display: flex;
    flex: 1;
}

.graph-skeleton-data {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 10px;
}

.graph-row-skeleton {
    display: flex;
    gap: 20px;
    align-items: center;
}


.circle-fol-graph {
    width: 22px;
    height: 22px;
    background-color: var(--skeleton-bg);
    border-radius: 50%;
}

.bar-skeleton-row {
    background-color: var(--skeleton-bg);
    height: 15px;
    flex: 1;
    border-radius: 4px;
}

.large {
    min-width: 40%;
}

.widget-spanning {
    display: flex;
    flex-direction: row;
    gap: 80px;
}

.info-start {
    color: var(--primary-color);
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
}

.info-start h2 {
    margin: 0;
    font-size: 24px;
}

.subtitle-socials-start {
    font-size: 16px;
    margin: 0;
    color: var(--subtitle-color);
}

.scale-image-social {
    width: 15vw;
    object-fit: contain;
}

.large-button {
    margin: 5px;
    padding: 14px;
    border-radius: 10px;
    background-color: var(--primary-color);
    text-decoration: none;
    color: var(--secondary-color);
    cursor: pointer;
}

.team-sel-description {
    font-size: 14px;
    color: var(--subtitle-color) !important;
}

.submit-button-teamcr {
    padding: 10px;
    border-radius: 10px;
    background-color: var(--primary-color);
    text-decoration: none;
    color: var(--secondary-color);
    cursor: pointer;
}

.submit-button-teamcr:disabled {
    background-color: var(--subtitle-color);
}



.selected .team-sel-description {
    color: black !important;
}