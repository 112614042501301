.dashboard-container {
    display: flex;
    height: 100vh;
    position: relative;
    overflow: hidden;
    background:
        radial-gradient(circle at 95% -10%, #b078ff4a 0%, transparent 40%),
        radial-gradient(circle at 10% 100%, #b078ff3e 0%, transparent 40%),
        radial-gradient(circle at 50% 35%, #b078ff2e 0%, transparent 30%),
        radial-gradient(circle at 75% 90%, #50ddf948 0%, transparent 40%),
        radial-gradient(circle at 30% 55%, #50ddf948 0%, transparent 40%);
    background-size: cover;
    background-repeat: no-repeat;
    background-color: var(--bg-color);
    min-height: 0;
}

.main-content {
    flex-grow: 1;
    padding: 20px;
    display: flex;
    flex-direction: column;
    min-height: 0;
}

.frosted {
    background: var(--frosted-bg);
    backdrop-filter: blur(20px);
    border: 1px solid var(--frosted-br);
    border-radius: 16px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.01);
    box-sizing: border-box;
}

.dynamic-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-height: 0;
}

.under-container {
    width: 100%;
    height: 100%;
    background-color: var(--bg-color);
}

h2 {
    color: var(--primary-color);
}

h1 {
    color: var(--primary-color);
}

h3 {
    color: var(--primary-color);
    margin: 0;
}

h4 {
    color: var(--primary-color);
    margin: 5px 0px;
}

.icon {
    color: var(--primary-color);
}

div {
    color: var(--primary-color);
}

.data-row {
    display: flex;
    flex-direction: row;
    align-items: center;

}

.recharts-surface {
    overflow: visible !important;
}

.recharts-wrapper {
    margin-left: -15px;
}

.selected-graph-option {
    font-weight: 800;
}

.custom-tooltip * {
    color: var(--primary-color);
    font-size: 16px;
    margin: 0;
}

.custom-tooltip {
    padding: 10px;
}

.filter-dropdown {
    position: relative;
    display: inline-block;
}

.filter-dropdown button {
    display: flex;
    align-items: center;
    gap: 10px;
}

.dropdown-toggle {
    background: var(--background-color);
    border: 1px solid var(--border-color);
    border-radius: 8px;
    padding: 8px 32px 8px 12px;
    color: var(--text-color);
    cursor: pointer;
    transition: all 0.2s ease;
    min-width: 120px;
    text-align: left;
}

.dropdown-toggle:hover {
    border-color: var(--primary-color);
}

.dropdown-toggle .arrow {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 0.8em;
}

.dropdown-content {
    position: absolute;
    top: 100%;
    left: 0;
    background: var(--frosted-bg);
    backdrop-filter: blur(10px);
    border: 1px solid var(--border-color);
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    padding: 8px;
    z-index: 1000;
    min-width: 200px;
    max-height: 300px;
    overflow-y: auto;
    margin-top: 4px;
}

.dropdown-item {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 6px;
    cursor: pointer;
    border-radius: 4px;
    font-size: 12px;
}

.dropdown-item:hover {
    background: var(--background-secondary);
}

.dropdown-item input[type="checkbox"] {
    width: 16px;
    height: 16px;
    accent-color: var(--primary-color);
}

.time-options {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.time-option {
    padding: 6px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 12px;
}

.time-option:hover {
    background: var(--skeleton-bg) !important;
}

.time-option.to-dd-selected {
    background: var(--frosted-br);
    color: white;
}

.ss-header {
    display: flex;
    flex-direction: row;
    gap: 50px;
    align-items: center;
    padding-top: 20px;

    justify-content: end;
}

.ss-header * {
    font-size: 14px;
}

.top-content-header th {
    padding-bottom: 5px;
    color: var(--subtitle-color);
    font-weight: normal !important;
}

.top-content-table {
    width: 100%;
    table-layout: auto;
}

.top-content-header th {
    font-size: 12px;
}

.top-content-header th:first-child {
    width: 100%;
    text-align: left;
}

.top-content-header th:not(:first-child) {
    white-space: nowrap;
    text-align: right;
    width: 1%;
    padding-left: 10px;
}

.start-content-col {
    max-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left !important;
    width: 100%;
}

.top-content-row td {
    font-size: 12px;
    padding-bottom: 10px;
}

.middle-col,
.end-content-col {
    white-space: nowrap;
    width: 1%;
    padding-left: 20px !important;
    text-align: right !important;
}

/* Add to media query for mobile */
@media (max-width: 768px) {
    .medium-container {
        overflow-x: auto;
    }

    .top-content-table {
        min-width: 500px;
    }
}

.normal-container .data-skeleton {
    gap: 15px;
    font-size: 12px;
}

.bold-dash {
    font-weight: bold;
    font-size: 14px;
}

.slim-dash {
    font-size: 14px;
}

.data-skeleton-db-dpc {
    display: flex;
    flex-direction: row;
}

.fallback-profile p {
    color: var(--subtitle-color);
    font-size: 14px;
    margin: 0;
}

.channel-post-item {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    padding: 10px;
    border-radius: 14px;
    background-color: var(--skeleton-bg);
}

.channel-post-item p {
    margin: 0;
    font-size: 14px;
}

.channel-post-item .channel-type-post {
    font-size: 12px;
    color: var(--subtitle-color);
}

.channel-post-item .channel-title-post {
    font-size: 14px;
    color: var(--primary-color);
}

.channel-checkbox-post {
    width: 15px;
    height: 15px;
}

.logo-title-container {
    display: flex;
    align-items: center;
}

.logo-title-container img {
    flex-shrink: 0;
}

.logo-title-container span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.logo-wrapper {
    position: relative;
    display: inline-block;
}

.channel-logo {
    position: absolute;
    bottom: 0%;
    right: 10%;
    width: 15px;
    height: 15px;
    border-radius: 50%;
}

.platform-logo {
    border-radius: 50%;
}