.analytics-body {
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    min-height: 100%;
    box-sizing: border-box;
    margin-top: 20px;
    gap: 20px;
    padding-bottom: 18px;
}

.analytics-page-sel {
    display: flex;
    flex-direction: row;

    width: 100%;
    gap: 20px;

    border-bottom: 1px solid var(--frosted-br);
}

.analytics-page-sel-item {
    display: flex;
    flex-direction: row;
    align-items: center;

    padding: 10px;
    box-sizing: border-box;
    gap: 10px;
    
    color: #696969;
    cursor: pointer;

    border-bottom: 0px solid transparent;
}

.analytics-page-sel-item.active {
    color: var(--primary-color);
    font-weight: 600;
    border-bottom: 2px solid var(--primary-color);
}

.analytics-page-filters {
    display: flex;
    flex-direction: row;
    gap: 20px;

    align-items: center;
    justify-content: space-between;
}

.analytics-page-filters-item {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.analytics-page-filters-item select {
    border-radius: 10px;
    padding: 5px;
    background-color: transparent;
    color: var(--primary-color);
}

.analytics-section {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.analytics-key-stats {
    display: flex;
    flex-direction: row;
    gap: 20px;
    width: 100%;
    justify-content: space-between;
}

.analytics-stat-item {
    display: flex;
    flex-direction: column;
    gap: 5px;
    flex: 1;
}

.analytics-stat-item p {
    margin: 0;
}

.small-container {
    grid-column: span 3;
    height: 100px;
    overflow: hidden;
}

.analytics-demo-map {
    display: flex;
    flex-direction: column;
    gap: 20px;

    height: 70vh;
    padding-bottom: 16px;

    align-items: center;
}

.viewSelector {
    display: flex;
    flex-direction: row;
    gap: 40px;

    border-radius: 8px;
    background-color: #696969;
    padding: 6px;

    justify-content: center;
    align-items: center;
}

.view-sel-item {
    color: white;
    cursor: pointer;
    padding: 8px;
    border-radius: 6px;
}

.view-sel-item.active {
    background-color: black;
}

.analytics-demo-grid {
    display: flex;
    gap: 20px; /* Space between columns */
    width: 100%;
}

.grid-column {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;
}

.grid-item {
    padding: 10px;
}

.country-view-item {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
}

.age-group-item {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
}

.gender-item {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
}

.device-type-item {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
}

.analytics-demo-map svg {
    border-radius: 15px;
}