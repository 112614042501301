.button-secondary {
    background-color: var(--primary-color);
    color: var(--secondary-color);
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    border: none;
}

.button-sub {
    background-color: var(--secondary-color);
    color: var(--primary-color);
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    border: none;
}

.billing-header {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 10px;
}

.billing-info {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.overview-section-billing {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.billing-info-item {
    display: flex;
    flex-direction: column;
}

.billing-info-item h4 {
    margin: 0;
    padding: 0;
    font-size: 14px;
}

.billing-info-item p {
    margin: 5px 0;
    padding: 0;
    font-size: 12px;
}

.billing-item-details {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}

.table-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 10px;
}

.billing-table {
    width: 100%;
    border-collapse: collapse;
}

.billing-table th {
    color: var(--primary-color);
    text-align: start;
    font-weight: 300;
    font-size: 14px;
    padding-bottom: 10px;
}

.billing-table td {
    color: var(--primary-color);
}

.mini-table-pod {
    width: 0px;
}