.modal-content-max {
    background: var(--popup-bg);
    padding: 24px;
    border-radius: 16px;
    width: 90vw;
    height: 85vh;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 18px;
}

.modal-header-post {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    padding-bottom: 18px;
    border-bottom: 1px solid var(--subtitle-color);
}

.modal-body-post {
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex: 1;
}

.modal-footer-post {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: flex-end;
}

.next-post {
    background-color: var(--primary-color);
    color: var(--secondary-color);
    padding: 8px 24px;
    border-radius: 8px;
    font-size: 14px;
}

.subtitle-posts {
    font-size: 14px;
    color: var(--subtitle-color);
    margin: 0;
}

.post-column {
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: var(--skeleton-bg);
    height: 100%;
    padding: 10px;
    border-radius: 14px;
    box-sizing: border-box;
}

.channel-post {
    min-width: 20%;
}

.upload-media {
    width: 30%;
}

.platform-settings {
    flex: 1;
}

.channel-select-post {
    padding: 4px;
    border-radius: 5px;
    background-color: transparent;
    color: var(--primary-color);
    border: 1px solid var(--subtitle-color);
}

.platform-changes-post {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.platform-change-page {
    cursor: pointer;
}

.platform-change-page.selected-platform {
    padding-bottom: 5px;
    border-bottom: 1px solid var(--primary-color);
}

.platform-change-page.disabled {
    pointer-events: none;
    opacity: 0.5;
}

.media-title-post {
    font-size: 14px;
    color: var(--subtitle-color);

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.media-title-post input {
    flex: 1;
    border-radius: 5px;
    padding: 5px;
    border: none;
    background-color: var(--secondary-color);
    color: var(--primary-color);
}

.media-title-post textarea {
    flex: 1;
    height: 100%;
    border-radius: 5px;
    padding: 5px;
    border: none;

    font-family: 'Inter', sans-serif;
    resize: none;
    background-color: var(--secondary-color);
    color: var(--primary-color);
    box-sizing: border-box;
}

.media-description-post {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.media-description-post textarea {
    border-radius: 5px;
    padding: 5px;
    border: none;
    background-color: var(--secondary-color);
    color: var(--primary-color);
    flex: 1;

    font-family: 'Inter', sans-serif;

    resize: none;
}

.no-variants {
    margin: 0;
    font-size: 14px;
}


.variant-item {
    font-size: 14px;
}

.set-all-post {
    background-color: var(--frosted-br);
    border-radius: 5px;
    padding: 2px;
    font-size: 12px;
    color: var(--primary-color);
    cursor: pointer;
}

.media-disabled {
    pointer-events: none;
    opacity: 0.5;
}

.platform-changes-settings {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 18px;
}

.platform-settings-item {
    border-bottom: 1px solid var(--subtitle-color);
    padding-bottom: 8px;
}

.platform-settings-header {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    cursor: pointer;
}

.platform-settings-header span {
    flex: 1;
    font-weight: bold;
}

.platform-setting-row {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    margin-bottom: 12px;
}

.platform-setting {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: start;
    flex: 1;
}

.platform-settings-content {
    padding-top: 12px;
}

.platform-setting label {
    font-size: 12px;
}

.platform-setting select {
    padding: 5px;
    border-radius: 5px;
    border: 1px solid var(--subtitle-color);
    background-color: transparent;
    width: 100%;
    color: var(--primary-color);
}

.platform-setting input {
    padding: 5px;
    border-radius: 5px;
    border: 1px solid var(--subtitle-color);
    background-color: transparent;
    width: 100%;
    color: var(--primary-color);
    box-sizing: border-box;
}



/* Drag & Drop Upload */
.upload-space {
    border: 2px dashed #ddd;
    border-radius: 8px;
    text-align: center;
    position: relative;
    transition: border-color 0.3s ease;
}

.drag-over {
    border-color: #646cff;
    background-color: rgba(100, 108, 255, 0.05);
}

.has-error {
    border-color: #ff4757;
}

.upload-label {
    cursor: pointer;
    display: block;
}

.upload-instructions {
    font-size: 0.8rem;
}

.upload-instructions small {
    display: block;
    color: #666;
    font-size: 0.7em;
    margin-top: 0.5rem;
}

.error-message {
    color: #ff4757;
    font-weight: 500;
}

.upload-previews {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    gap: 1rem;
    margin-top: 1.5rem;
}

.file-preview {
    position: relative;
    border-radius: 4px;
    overflow: hidden;
}

.file-preview img,
.file-preview video {
    object-fit: contain;
    display: block;
    height: 25vh;
}

.video-preview {
    position: relative;
}

.play-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 24px;
    color: white;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.remove-file {
    position: absolute;
    top: 4px;
    right: 4px;
    background: rgba(0, 0, 0, 0.6);
    border: none;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    cursor: pointer;
    color: white;
}

.file-name {
    display: block;
    font-size: 0.75rem;
    padding: 4px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

.upload-container-inner {
    padding: 20px;
}

.preview-container {
    position: relative;
    height: 100%;
    width: 100%;
}

.blurred-background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    filter: blur(20px);
    opacity: 0.4;
    z-index: 1;
}

.blurred-background img,
.blurred-background video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.main-preview {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
}

.foreground-uploading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    pointer-events: none;
}

.foreground-uploading>* {
    pointer-events: auto;
}

.file-preview {
    position: relative;
    max-width: 80%;
    max-height: 80%;
    display: none;
}

.file-preview.active {
    display: block;
}

.file-preview img,
.file-preview video {
    max-height: 300px;
    max-width: 100%;
    object-fit: contain;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.nav-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s ease;
    z-index: 4;
}

.nav-arrow.left {
    left: 20px;
}

.nav-arrow.right {
    right: 20px;
}

.remove-file {
    position: absolute;
    top: 15px;
    right: 15px;
    background: rgba(0, 0, 0, 0.6);
    border: none;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    cursor: pointer;
    transition: all 0.3s ease;
    z-index: 5;
}

.remove-file:hover {
    background: rgba(0, 0, 0, 0.8);
    transform: scale(1.1);
}

.add-more-button {
    position: absolute;
    bottom: 20px;
    right: 20px;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 8px;
    background: var(--primary-color);
    color: var(--secondary-color);
    border-radius: 20px;
    cursor: pointer;
    z-index: 5;
    transition: all 0.2s ease;
}

.add-more-button span {
    font-size: 0.9rem;
    font-weight: 500;
}

.platform-setting-item {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    white-space: nowrap;
}

.settings-seperate {
    color: var(--subtitle-color);
    margin: 5px 0;
    font-size: 14px;
}

.media-title-post-full {
    height: 100%;
}

.icon-post-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--subtitle-color);
    padding: 8px;
    border-radius: 8px;
    cursor: pointer;
    color: white;
}

.date-picker-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.date-picker-modal {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 500px;
    max-height: 80vh;
    overflow-y: auto;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.date-picker-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.date-picker-content {
    display: flex;
    gap: 20px;
}

.calendar-container {
    flex: 1;
}

.time-selectors {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
}

.time-select {
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
    font-size: 14px;
    width: 120px;
}

.date-picker-footer {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
}

.date-picker-footer button {
    padding: 8px 16px;
    background: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background 0.2s;
}

.date-picker-footer button:hover {
    background: #0056b3;
}

/* Optional: Customize react-datepicker styles */
.react-datepicker {
    border: 1px solid #ccc;
    border-radius: 4px;
}

.react-datepicker__header {
    background: #f8f9fa;
    border-bottom: 1px solid #ccc;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
    background-color: #007bff;
    color: white;
}

.react-datepicker__day:hover {
    background-color: #e9ecef;
}