.title-settings {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}

.fill {
    flex-grow: 1;
}

.add-button {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    padding: 10px;

    cursor: pointer;
}

.add-button:hover {
    background: var(--frosted-bg);
    border: 1px solid var(--frosted-br);
    border-radius: 15px;
}

/* Modal styles */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(10px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: var(--popup-bg);
    padding: 20px;
    border-radius: 16px;
    width: 90%;
    max-width: 50vw;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    position: relative;
    
    min-height: 50vh;
    max-height: 85vh;
    display: flex;
    flex-direction: column;
}

.modal-main-list {
    min-height: 0;
    display: flex;
    flex-direction: column;
    flex: 1;
}

.modal-close-button {
    position: absolute;
    top: 1rem;
    right: 1rem;
    padding: 0.5rem 1rem;
    background: #f0f0f0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.modal-header {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}

.modal-header h2 {
    margin: 8px 0;
}

.upload-area {
    border: 2px dashed #cccccc3e;
    border-radius: 8px;
    padding: 2rem;
    margin-bottom: 16px;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s ease;
    width: 2vw;
    height: 2vw;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
}

.modal-title {
    position: absolute; /* Position the title absolutely */
    left: 50%; /* Move it to the center */
    transform: translateX(-50%); /* Adjust for exact centering */
    margin: 0; /* Remove default margin */
}

.upload-area.dragging {
    border-color: #646cff;
    background-color: rgba(100, 108, 255, 0.1);
}

.show-background {
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(10px);
}

.upload-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    object-fit: cover;
    gap: 1rem;
    color: #666;

    position: absolute;
}

.upload-content img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 1;
    border-radius: 10px;
    max-width: none;
    max-height: none;

    position: absolute;
}

.upload-content p {
    margin: 0;
    color: var(--subtitle-color);
}

.brand-name-input {
    margin: 1rem 0;
    display: flex;
    flex-direction: column;
}

.brand-name-input h4 {
    margin-bottom: 0.5rem;
    margin-top: 0px;
    color: var(--primary-color);
}

.brand-name-input input {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 1rem;
    flex: 1;
}

.modal-footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
}

.done-button {
    background-color: var(--primary-color);
    color: var(--bg-color);
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
    flex: 1;
}

.done-button:disabled {
    background-color: #cccccc5d;
    cursor: not-allowed;
}

.upload-section {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.upload-section p {
    margin: 0;
    color: var(--subtitle-color);
    font-size: 14px;
}

.scrollable {
    overflow-y: auto;
    max-height: 70vh;
}

.brand-card {
    padding: 20px;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    width: 100%; /* Ensure cards take full width */
    box-sizing: border-box; /* Prevent overflow */
}

.brand-card-header {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}

.brand-card-header img {
    width: 36px;
    height: auto;
    border-radius: 25%;
    aspect-ratio: 1/1;
    object-fit: cover;
}

.main-content-setting {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0px;
    height: 100%;
    max-height: max-content;
}

.brand-card-header h3 {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
}

.add-channel-button {
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
    padding: 10px;
    background-color: var(--skeleton-bg);
    border-radius: 10px;
    font-size: 14px;

    cursor: pointer;
    color: var(--primary-color);
}

.interactive {
    cursor: pointer;
}

.channel-list {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

.channel-card {
    display: flex;
    padding: 5px;
    align-items: center;
    border-radius: 8px;
    background-color: var(--skeleton-bg);
    border: 1px solid var(--frosted-br);
    flex: 1 1 auto;
    box-sizing: border-box;
    gap: 5px;
}

.channel-list-divider {
    border-top: 1px solid var(--frosted-br);
    margin: 10px 0;
}

.dropdown-menu {
    position: absolute;
    right: 20px;
    top: 50px;
    background: var(--popup-bg);
    border: 1px solid var(--frosted-br);
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    z-index: 100;
    min-width: 160px;
    overflow: hidden;
}

.dropdown-menu-edit {
    position: absolute;
    right: 80px;
    top: 40px;
    background: var(--popup-bg);
    border: 1px solid var(--frosted-br);
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    z-index: 100;
    min-width: 160px;
    overflow: hidden;
}

.NC-Shift {
    top: 52% !important;
    right: 10px;
}

.add-container {
    display: flex;
    flex-direction: row;
    gap: 10px;
    position: relative;
}

.dropdown-item {
    padding: 12px 16px;
    font-size: 14px;
    color: var(--text-color);
    cursor: pointer;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    gap: 8px;
}

.dropdown-item:hover {
    background: var(--hover-bg);
}

.edit-channel-header {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    margin-bottom: 10px;
}

.edit-channel-header h4 {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
}

.edit-channel-list {
    display: flex;
    flex-direction: column;
    gap: 16px;
    overflow-y: auto;
    min-height: 0;
    height: 100%;
    margin-bottom: 25px;
    padding-bottom: 16px;
    padding-top: 8px;
}

.channel-card-edit {
    display: flex;
    padding: 12px;
    border-radius: 12px;
    background-color: var(--skeleton-bg);
    gap: 5px;
}

.edit-card-header {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    width: 100%;
    flex-grow: 1;
    position: relative;
}

.delete-edit-button {
    background-color: var(--skeleton-bg);
    color: var(--error-color);
    border: none;
    padding: 8px 16px;
    font-size: 14px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
}

.move-edit-button {
    background-color: var(--skeleton-bg);
    color: var(--primary-color);
    border: none;
    padding: 8px 16px;
    font-size: 14px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
}