.main-header-control p {
    margin: 0;
    color: var(--subtitle-color);
    font-size: 14px;

    cursor: pointer;
    transition: padding 0.4s, color 0.4s;
}

.tableoptionselected {
    color: var(--primary-color) !important;
    padding-bottom: 2px;
    border-bottom: 2px solid var(--primary-color);
    font-weight: 600;
}

.table-header-user p {
    color: var(--subtitle-color) !important;
    font-size: 14px;
    font-weight: 500;
}

.modal-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.newEmailInput {
    padding: 8px;
    border: 0px;
    background-color: var(--skeleton-bg);
    border-radius: 8px;
    font-size: 14px;
    flex: 1;
}

.modal-content h4 {
    margin-bottom: 10px;
    margin-top: 0px;
    color: var(--primary-color);
}

.enter-button-addnew {
    background-color: var(--skeleton-bg);
    color: var(--bg-color);
    border: none;
    padding: 8px;
    border-radius: 4px;
    cursor: pointer;
}

.enter-button-addnew.active {
    background-color: var(--primary-color) !important;
}

.enter-button-addnew.active .icon {
    color: var(---color) !important;
}

.new-user-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.new-user-item select {
    padding: 8px;
    border: 0px;
    background-color: var(--skeleton-bg);
    border-radius: 8px;
    font-size: 14px;
}

.center-bottom {
    display: flex;
    justify-content: center;
    font-size: 12px;
    cursor: pointer;
    align-items: center;
    color: rgb(0, 42, 255);
}

.center-bottom .icon {
    color: rgb(0, 42, 255);
}

.table-actions {
    position: relative;
}

.table-actions:hover {
    cursor: pointer;
}

.dropdown-menu-edit.users-dd {
    position: absolute;
    right: 10px;
    top: calc(50% + 5px);
    background-color: var(--bg-color);
    border: 1px solid var(--skeleton-bg);
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    z-index: 1000;
    /* Ensure it appears above other elements */
    min-width: 160px;
    padding: 8px 0;
}


.dropdown-item.remove {
    color: var(--error-color);
}

.dropdown-divide {
    border-top: 1px solid var(--skeleton-bg);
    margin: 0px 5px;
}